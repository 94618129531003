.home-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-image: url(../images/Home2.png); /* Make sure path is correct */
  color: #fff;
  padding-top: 100px;
  padding-bottom: 150px;
  padding-left: 120px;
  background-size: 100%; /* Start size */
  background-position: center;
  background-repeat: no-repeat;
  animation: backgroundZoom 1s ease-out forwards; /* Added 'forwards' to keep the end state */
}

/* Define the keyframes for the zoom effect */
@keyframes backgroundZoom {
  0% { background-size: 100%; }
  100% { background-size: 110%; } /* This is where it will stay at the end of the animation */
}

.content-area {
  width: 50%;
}

.content-area img {
height: 60px;
padding-bottom: 10px;
}

.content-area h1 {
  font-size: 90px;
  margin-bottom: 10px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 3000;
  font-style: normal;
}

.content-area p {
  font-size: 25px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.trial-button, .view-plans-button {
  padding: 10px 20px;
  border: none;
  color: white;
  background-color: #1DB954; /* Example color */
  cursor: pointer;
}

.trial-button {
  background-color: #E0A0B0; /* Soft pink */
}

.view-plans-button {
  background-color: transparent;
  border: 1px solid #fff;
}

.image-area {
background-image: url('../images/Home2.png');
background-size: cover;
background-position: center;
}

.textmyguy {
color: rgb(0, 123, 255);
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
margin-bottom: 0px;
}

@media (max-width: 768px) {
.home-container {
  flex-direction: column;
}

.content-area, .image-area {
  width: 100%;
}
}
