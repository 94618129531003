.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Change to fixed to always stay on top */
  top: 0; /* Set the top to 0 */
  left: 0; /* Ensure it spans from the left edge */
  right: 0; /* Ensure it spans to the right edge */
  z-index: 1000; /* High z-index to keep it above other content */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.header-logo {
  height: 2em; /* Adjust the image size */
  width: auto; /* Keep the aspect ratio */
  margin-right: 10px; /* Space between the image and text */
  margin-bottom: 6px;
  margin-top: 6px;
}

/* Additional styling for when the page is scrolled */
.header.scrolled {
  background-color: rgb(0, 0, 0); /* Less transparency when scrolled */
}

.header-title {
  font-size: 25px; /* Increase the fosnt size */
  color: white; /* Text color */
  margin: 0; /* Remove default margin */
}
