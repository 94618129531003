.partner-restaurants1 {
    width: 100%;
    overflow: hidden; /* Hides anything outside this container */
    position: relative;
    background: rgb(230, 232, 235); /* Light pink background */
    padding: 20px 0;
    padding-bottom: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2); /* Optional: Adds shadow for depth */
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.partner-restaurants1 h2 {
    text-align: center;
    margin-bottom: 10px;
}

.scrolling-wrapper {
    display: flex;
    white-space: nowrap;
    animation: scroll 60s linear infinite; /* Continuous scrolling animation */
}

.scrolling-wrapper .Ye {
    width: 100px; /* Set a more reasonable width */
    height: auto;
    max-height: 200px; /* Maximum height to maintain uniformity */
    margin: 0 40px; /* Space between logos */
    will-change: transform; /* Optimizes the animation */
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
    font-size: 25px;
    color: gray;
}

@keyframes scroll {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); } /* Adjust this value to control the scrolling speed */
}

/* Responsiveness: Reduces speed on hover to make logos more visible */
.scrolling-wrapper:hover {
    animation-play-state: paused;
}
