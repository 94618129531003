.about-section {
    background: #FFF;
    color: #333;
    padding: 40px 80px;
    font-family: 'Arial', sans-serif;
    padding-bottom: 100px;
  }
  
  .section-header {
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
    font-weight: bold;
    
  }
  
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .main-item {
    flex-basis: 60%; /* Larger section for the main item */
    padding-right: 20px; /* Space between main and side items */
  }
  
  .side-items {
    display: flex;
    flex-direction: column;
    flex-basis: 35%; /* Smaller sections for the side items */
    gap: 20px; /* Space between side items */
  }
  
  .main-item h2, .side-item h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .main-item p, .side-item p {
    font-size: 18px;
  }
  
  .main-item button, .side-item button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }

  /* Additional styles for AboutSection.css */

.main-item h2 {
    margin-top: 30px;
    font-size: 60px; /* Increased from 20px for more prominence */
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
    margin-bottom: 20px; /* More spacing below the heading */
  }
  
  .main-item p {
    font-size: 20px; /* Larger paragraph font size for better readability */
    line-height: 1.6; /* Increased line height for readability */
   
  }
  
  .main-item button {
    padding: 12px 24px; /* Slightly larger button */
    font-size: 16px; /* Larger font size for the button text */
  }
  
  /* Ensure all changes maintain good design practices and readability */
  
  .side-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */
    gap: 20px; /* Space between grid items */
    margin-top: 20px; /* Optional: adds space above the grid */
  }
  
  .side-item {
    width: 400px;
    padding: 20px;
    background-color: #ffffff; /* Light grey background */
    border-left: 4px solid #000000; /* Light grey border */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  }
  
  /* Adjust text styles if needed */
  .side-item h2 {
    font-size: 50px; /* Adjust size as necessary */
    margin-bottom: 10px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
  }
  
  .side-item p {
    font-size: 18px;
 
  }
  
  @media (max-width: 992px) {
    .main-container {
      flex-direction: column;
    }
  
    .main-item, .side-items {
      flex-basis: 100%;
    }
  
    .main-item, .side-items {
        flex-basis: 100%; /* Full width on smaller screens */
        padding-right: 0; /* Remove padding on smaller screens */
      }
  }
  