

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Adjust to center the container items */
    width: 100%; /* Full width of the container */
    max-width: 100%; /* Maximum width of the content */
    margin: 0 auto; /* Automatically margin for horizontal centering */
    background: rgb(230, 232, 235); /* Light pink background */
    color: rgb(0, 0, 0);
    
  }


  .contact-text {
    display: flex;
    flex-direction: column;
    align-items: center; /* Adjust to center the container items */
    width: 100%; /* Full width of the container */
    max-width: 90%; /* Maximum width of the content */
    margin: 0 auto; /* Automatically margin for horizontal centering */
    padding-top: 10px;
    color: rgb(0, 0, 0);
    font-family: Arial, sans-serif;
    font-size: 20px;
    border-top: 4px solid #333; /* Adds a solid, dark top border */
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
  }


  
  
  .email {
    font-size: 34px;
    text-decoration: underline;
    text-align: left; /* Aligns the email to the left */
    color: black; /* Changes email text color to black */
    width: 100%; /* Makes sure the email takes full width */
    margin-bottom: 20px; /* Adds spacing below the email */
}

  .contact-container h1 {
    font-size: 60px;
    margin-bottom: 26px;
    width: 100%; /* Full width */
    text-align: left; /* Align title to the left */
  }
  
  .contact-container p{
    width: 100%; /* Full width */
    text-align: left; /* Align paragraphs to the left */
   
    margin-bottom: 20px; /* Margin bottom for spacing */
  }
  