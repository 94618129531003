.setup-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Center vertically */
    width: 100%; /* Take up full container width */
    background: rgb(230, 232, 235); /* Light pink background */
    font-family: Arial, sans-serif;
    padding-top: 135px;
    padding-bottom: 130px;
}

.setup-container li {
    font-size: 25px;
    
    margin-bottom: 20px;
    
}


.setup-instructions {
    padding-right: 50px; /* Space between the text and the plan card */
    text-align: right; /* Right align the text block content */
}

.setup-instructions h1 {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
    font-size: 80px;
    margin-bottom: 20px;
}

.setup-instructions ol {
    padding: 0; /* Remove padding to align numbers closer to the text */
    margin: 0;
    list-style-position: inside; /* Adjust list numbers to be inside the text flow */
}

.as{
    color: red;
}

.plan-card {
    background: rgb(224, 226, 230);
    color: rgb(0, 0, 0);
    padding: 20px;
    width: 300px; /* Adjust width as needed */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Subtle shadow for depth */


}

.plan-title {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 3000;
    font-style: normal;
    border-bottom: 4px solid #000000; /* Light grey border */

    font-size: 40px; /* Larger font size */
    font-weight: bold; /* Bold font weight */
    display: block; /* Block display for full width */
    text-align: center; /* Centered text */
    padding: 10px;
}
.plan-title2 {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 10;
    font-style: normal;
    border-bottom: 4px solid #000000; /* Light grey border */

    font-size: 20px; /* Larger font size */
    display: block; /* Block display for full width */
    text-align: center; /* Centered text */

}

.plan-title3 {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 1000;
    font-style: normal;

    font-size: 20px; /* Larger ont size */
    display: block; /* Block display for full width */

}

.plan-title4 {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 1;
    font-style: normal;
    display: block; /* Block display for full width */
}



.plan-card p, .plan-card ul, .plan-card li {
    margin: 5px 0;
    padding: 5px;
    list-style-type: none;
}

.plan-card button {
    background-color: #FF69B4;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.plan-card button:hover {
    background-color: #FF1493;
}
